import React from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi';
import CollapsableField from './collapsableField';
import { BiIdCard } from 'react-icons/bi';

export default function Reservation({ idPartecipante, clienti, stanze, collaborators, acconto, saldo, totale, metodoAcconto, dataAcconto, destinatarioAcconto, metodoSaldo, destinatarioSaldo, stato, DeleteReservationPressed, UpdateReservationData }) {

    let nomePartecipante, cognomePartecipante, cfPartecipante, partecipanteTesserato, notePartecipante, nomePacchetto, dataInizioPacchetto, dataFinePacchetto, dataIn, dataOut, idStanza, codiceStanza, nomeHotel, tipoStanza, collocatiStanza, noteStanza, idCollaboratore, nomeCollaboratore, cognomeCollaboratore;

    for (let i = 0; i < clienti.length; i++){
        if (idPartecipante == clienti[i].id){

            nomePartecipante = clienti[i].nome;
            cognomePartecipante = clienti[i].cognome;
            cfPartecipante = clienti[i].cf;
            partecipanteTesserato = clienti[i].tesserato;
            notePartecipante = clienti[i].note;
            nomePacchetto = clienti[i].nomePacchetto;
            // dataInizioPacchetto = clienti[i].dataInizioPacchetto;
            // dataFinePacchetto = clienti[i].dataFinePacchetto;
            dataIn = clienti[i].dataIn;
            dataOut = clienti[i].dataOut;
            idStanza = clienti[i].idStanza;
            codiceStanza = clienti[i].codiceStanza;
            nomeHotel = clienti[i].nomeHotel;

            for (let s = 0; s < stanze.length; s++){
                if (idStanza == stanze[s].id){
                    tipoStanza = stanze[s].tipo;
                    noteStanza = stanze[s].note;
                    collocatiStanza = JSON.parse(JSON.stringify(stanze[s].collocati));

                    collocatiStanza.forEach(partecipante => {
                        if (partecipante.idPartecipante !== idPartecipante) {
                            partecipante.clickable = true;
                        } else {
                            partecipante.clickable = false;
                        }
                    });
                }
            }

            idCollaboratore = clienti[i].idCollaboratore;
            for (let c = 0; c < collaborators.length; c++){
                if(idCollaboratore == collaborators[c].id){
                    nomeCollaboratore = collaborators[c].nome;
                    cognomeCollaboratore = collaborators[c].cognome;
                }
            }

        }
    }

    const ToggleReservation = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'reservation-data'){
                if (sections[i].className == 'reservation-data'){
                    sections[i].className = 'reservation-data reservation-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'reservation-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='reservation-div'>
            <div className='toggle-reservation-div'> <button className='toggle-button' onClick={e => ToggleReservation(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='reservation-name'>
                <h3>{cognomePartecipante} {nomePartecipante} {partecipanteTesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
                <div class='top-divider' />
            </div>

            <div id='reservation-data' class='reservation-data reservation-data-collapsed'>

                <div className='data-field reservation-bundle-field'>
                    <h4 className='data-field-key'> Pacchetto: </h4>
                    <div className='data-field-value'>
                        <h4> {nomePacchetto} </h4>
                    </div>
                </div>

                <div className='data-field reservation-state-field'>
                    <h4 className='data-field-key'> Stato: </h4>
                    <div className='data-field-value'>
                        <h4> {stato} </h4>
                    </div>
                </div>

                {idStanza != null && idStanza != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data In: </h4>
                        <div className='data-field-value'>
                            <h4> {dataIn} </h4>
                        </div>
                    </div>
                }
                {idStanza != null && idStanza != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data Out: </h4>
                        <div className='data-field-value'>
                            <h4> {dataOut} </h4>
                        </div>
                    </div>
                }
                
                {idCollaboratore != null && idCollaboratore != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> {cognomeCollaboratore} {nomeCollaboratore} </h4>
                        </div>
                    </div>
                }
                
                {(idCollaboratore == null || idCollaboratore == '') && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {notePartecipante &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> {notePartecipante} </h4>
                        </div>
                    </div>
                }

                {idStanza != null && idStanza != '' &&
                    <CollapsableField UpdateReservationData={UpdateReservationData} mainKey='Camera' mainValue={'Camera ' + codiceStanza + ' - ' + nomeHotel} subFields={[{key: 'Tipo', value: tipoStanza}, {key: 'Prenotazioni', value: collocatiStanza, isPeopleList: true}, {key: 'Note', value: noteStanza}]} />
                }

                <CollapsableField
                    mainKey='Pagamento'
                    mainValue=''
                    subFields={[
                        {key: 'Acconto', value: acconto + '€'},
                        {key: 'Saldo', value: saldo + '€'},
                        {key: 'Totale', value: totale + '€'},
                        {key: 'Metodo acconto', value: metodoAcconto},
                        {key: 'Data acconto', value: dataAcconto},
                        {key: 'Dest. acconto', value: destinatarioAcconto},
                        {key: 'Metodo saldo', value: metodoSaldo},
                        {key: 'Dest. saldo', value: destinatarioSaldo},
                        {key: 'Stato', value: stato, fixed: true}
                    ]}
                />

                <div className='bottom-divider'></div>
                <div className='reservation-commands'>
                    <a className='clickable' onClick={() => UpdateReservationData(idPartecipante)} >Modifica</a>
                    <a className='clickable' onClick={() => DeleteReservationPressed(idPartecipante)} >Elimina</a>
                </div>

            </div>
        </div>
    )
}

export function ReservationToSettle({ idPartecipante, clienti, stanze, collaborators, acconto, saldo, totale, metodoAcconto, dataAcconto, destinatarioAcconto, metodoSaldo, destinatarioSaldo, stato, reservationsToSettleIDs, ToggleReservationToSettle }) {

    let nomePartecipante, cognomePartecipante, cfPartecipante, partecipanteTesserato, nomePacchetto, dataInizioPacchetto, dataFinePacchetto, dataIn, dataOut, idStanza, codiceStanza, nomeHotel, tipoStanza, collocatiStanza, noteStanza, idCollaboratore, nomeCollaboratore, cognomeCollaboratore;

    for (let i = 0; i < clienti.length; i++){
        if (idPartecipante == clienti[i].id){

            nomePartecipante = clienti[i].nome;
            cognomePartecipante = clienti[i].cognome;
            cfPartecipante = clienti[i].cf;
            partecipanteTesserato = clienti[i].tesserato;
            nomePacchetto = clienti[i].nomePacchetto;
            // dataInizioPacchetto = clienti[i].dataInizioPacchetto;
            // dataFinePacchetto = clienti[i].dataFinePacchetto;
            dataIn = clienti[i].dataIn;
            dataOut = clienti[i].dataOut;
            idStanza = clienti[i].idStanza;
            codiceStanza = clienti[i].codiceStanza;
            nomeHotel = clienti[i].nomeHotel;

            for (let s = 0; s < stanze.length; s++){
                if (idStanza == stanze[s].id){
                    tipoStanza = stanze[s].tipo;
                    noteStanza = stanze[s].note;
                    collocatiStanza = stanze[s].collocati;
                }
            }

            idCollaboratore = clienti[i].idCollaboratore;
            for (let c = 0; c < collaborators.length; c++){
                if(idCollaboratore == collaborators[c].id){
                    nomeCollaboratore = collaborators[c].nome;
                    cognomeCollaboratore = collaborators[c].cognome;
                }
            }

        }
    }

    let toBeSettled = false;
    for (let i = 0; i < reservationsToSettleIDs.length; i++){
        if (reservationsToSettleIDs[i] == idPartecipante){
            toBeSettled = true;
        }
    }

    const ToggleReservation = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'reservation-data'){
                if (sections[i].className == 'reservation-data'){
                    sections[i].className = 'reservation-data reservation-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'reservation-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='reservation-div reservation-to-settle'>
            <div className='multi-delete-checkbox-div'> <input type='checkbox' checked={toBeSettled} onClick={() => ToggleReservationToSettle(idPartecipante)} /> </div>
            <div className='toggle-reservation-div'> <button className='toggle-button' onClick={e => ToggleReservation(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='reservation-name'>
                <h3>{cognomePartecipante} {nomePartecipante} {partecipanteTesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
                <div class='top-divider' />
            </div>

            <div id='reservation-data' class='reservation-data reservation-data-collapsed'>

                <div className='data-field reservation-bundle-field'>
                    <h4 className='data-field-key'> Pacchetto: </h4>
                    <div className='data-field-value'>
                        <h4> {nomePacchetto} </h4>
                    </div>
                </div>

                <div className='data-field reservation-state-field'>
                    <h4 className='data-field-key'> Stato: </h4>
                    <div className='data-field-value'>
                        <h4> {stato} </h4>
                    </div>
                </div>

                {idStanza != null && idStanza != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data In: </h4>
                        <div className='data-field-value'>
                            <h4> {dataIn} </h4>
                        </div>
                    </div>
                }
                {idStanza != null && idStanza != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data Out: </h4>
                        <div className='data-field-value'>
                            <h4> {dataOut} </h4>
                        </div>
                    </div>
                }
                
                {idCollaboratore != null && idCollaboratore != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> {cognomeCollaboratore} {nomeCollaboratore} </h4>
                        </div>
                    </div>
                }
                
                {(idCollaboratore == null || idCollaboratore == '') && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {idStanza != null && idStanza != '' &&
                    <CollapsableField mainKey='Camera' mainValue={'Camera ' + codiceStanza + ' - ' + nomeHotel} subFields={[{key: 'Tipo', value: tipoStanza}, {key: 'Prenotazioni', value: collocatiStanza, isPeopleList: true}, {key: 'Note', value: noteStanza}]} />
                }

                <CollapsableField
                    mainKey='Pagamento'
                    mainValue=''
                    subFields={[
                        {key: 'Acconto', value: acconto + '€'},
                        {key: 'Saldo', value: saldo + '€'},
                        {key: 'Totale', value: totale + '€'},
                        {key: 'Metodo acconto', value: metodoAcconto},
                        {key: 'Data acconto', value: dataAcconto},
                        {key: 'Dest. acconto', value: destinatarioAcconto},
                        {key: 'Metodo saldo', value: metodoSaldo},
                        {key: 'Dest. saldo', value: destinatarioSaldo},
                        {key: 'Stato', value: stato, fixed: true}
                    ]}
                />

                <div className='bottom-divider'></div>

            </div>
        </div>
    )

}

export function ReservationToDelete({ idPartecipante, clienti, stanze, collaborators, acconto, saldo, totale, metodoAcconto, dataAcconto, destinatarioAcconto, metodoSaldo, destinatarioSaldo, stato, reservationsToDeleteIDs, ToggleReservationToDelete }) {

    let nomePartecipante, cognomePartecipante, cfPartecipante, partecipanteTesserato, nomePacchetto, dataInizioPacchetto, dataFinePacchetto, dataIn, dataOut, idStanza, codiceStanza, nomeHotel, tipoStanza, collocatiStanza, noteStanza, idCollaboratore, nomeCollaboratore, cognomeCollaboratore;

    for (let i = 0; i < clienti.length; i++){
        if (idPartecipante == clienti[i].id){

            nomePartecipante = clienti[i].nome;
            cognomePartecipante = clienti[i].cognome;
            cfPartecipante = clienti[i].cf;
            partecipanteTesserato = clienti[i].tesserato;
            nomePacchetto = clienti[i].nomePacchetto;
            // dataInizioPacchetto = clienti[i].dataInizioPacchetto;
            // dataFinePacchetto = clienti[i].dataFinePacchetto;
            dataIn = clienti[i].dataIn;
            dataOut = clienti[i].dataOut;
            idStanza = clienti[i].idStanza;
            codiceStanza = clienti[i].codiceStanza;
            nomeHotel = clienti[i].nomeHotel;

            for (let s = 0; s < stanze.length; s++){
                if (idStanza == stanze[s].id){
                    tipoStanza = stanze[s].tipo;
                    noteStanza = stanze[s].note;
                    collocatiStanza = stanze[s].collocati;
                }
            }

            idCollaboratore = clienti[i].idCollaboratore;
            for (let c = 0; c < collaborators.length; c++){
                if(idCollaboratore == collaborators[c].id){
                    nomeCollaboratore = collaborators[c].nome;
                    cognomeCollaboratore = collaborators[c].cognome;
                }
            }

        }
    }

    let toBeDeleted = false;
    for (let i = 0; i < reservationsToDeleteIDs.length; i++){
        if (reservationsToDeleteIDs[i] == idPartecipante){
            toBeDeleted = true;
        }
    }

    const ToggleReservation = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'reservation-data'){
                if (sections[i].className == 'reservation-data'){
                    sections[i].className = 'reservation-data reservation-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'reservation-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='reservation-div reservation-to-settle'>
            <div className='multi-delete-checkbox-div'> <input type='checkbox' checked={toBeDeleted} onClick={() => ToggleReservationToDelete(idPartecipante)} /> </div>
            <div className='toggle-reservation-div'> <button className='toggle-button' onClick={e => ToggleReservation(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='reservation-name'>
                <h3>{cognomePartecipante} {nomePartecipante} {partecipanteTesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
                <div class='top-divider' />
            </div>

            <div id='reservation-data' class='reservation-data reservation-data-collapsed'>

                <div className='data-field reservation-bundle-field'>
                    <h4 className='data-field-key'> Pacchetto: </h4>
                    <div className='data-field-value'>
                        <h4> {nomePacchetto} </h4>
                    </div>
                </div>

                <div className='data-field reservation-state-field'>
                    <h4 className='data-field-key'> Stato: </h4>
                    <div className='data-field-value'>
                        <h4> {stato} </h4>
                    </div>
                </div>

                {idStanza != null && idStanza != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data In: </h4>
                        <div className='data-field-value'>
                            <h4> {dataIn} </h4>
                        </div>
                    </div>
                }
                {idStanza != null && idStanza != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data Out: </h4>
                        <div className='data-field-value'>
                            <h4> {dataOut} </h4>
                        </div>
                    </div>
                }
                
                {idCollaboratore != null && idCollaboratore != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> {cognomeCollaboratore} {nomeCollaboratore} </h4>
                        </div>
                    </div>
                }
                
                {(idCollaboratore == null || idCollaboratore == '') && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {idStanza != null && idStanza != '' &&
                    <CollapsableField mainKey='Camera' mainValue={'Camera ' + codiceStanza + ' - ' + nomeHotel} subFields={[{key: 'Tipo', value: tipoStanza}, {key: 'Prenotazioni', value: collocatiStanza, isPeopleList: true}, {key: 'Note', value: noteStanza}]} />
                }

                <CollapsableField
                    mainKey='Pagamento'
                    mainValue=''
                    subFields={[
                        {key: 'Acconto', value: acconto + '€'},
                        {key: 'Saldo', value: saldo + '€'},
                        {key: 'Totale', value: totale + '€'},
                        {key: 'Metodo acconto', value: metodoAcconto},
                        {key: 'Data acconto', value: dataAcconto},
                        {key: 'Dest. acconto', value: destinatarioAcconto},
                        {key: 'Metodo saldo', value: metodoSaldo},
                        {key: 'Dest. saldo', value: destinatarioSaldo},
                        {key: 'Stato', value: stato, fixed: true}
                    ]}
                />

                <div className='bottom-divider'></div>

            </div>
        </div>
    )

}