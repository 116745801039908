import { MenuItem, Select, TextField, TextareaAutosize } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { Masonry } from 'react-masonry-responsive';
import * as yup from 'yup'
import CreateClient, { AddClientWithBundleToRoom } from '../../../../serverRequests/postRequests';
import UpdateDBClientData, { UpdatePernottamentoData } from '../../../../serverRequests/putRequests';
import Bundle from '../../../collaborator/components/bundle';
import { ChoiceRoom } from '../../../collaborator/components/room';
import { BiIdCard } from 'react-icons/bi';
import { ChoiceClient, ChoiceTesserato } from '../../../collaborator/components/client';
import { UpdateClienteNote } from '../../../../serverRequests/patchRequests';

const fullValidationSchema = yup.object({
    nome: yup
    .string('Nome deve essere una stringa')
    .required('Nome obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    cognome: yup
    .string('Cognome deve essere una stringa')
    .nullable(true)
    .max(50, 'Massimo 50 caratteri'),
    cf: yup
    .string('Codice Fiscale deve essere una stringa')
    .max(50, 'Massimo 50 caratteri'),
    sesso: yup
    .string('Sesso deve essere una stringa')
    .required('Sesso obbligatorio')
    .max(5, 'Scegliere una delle opzioni tra M, F o Altro'),
    indirizzo: yup
    .string('Indirizzo deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
    email: yup
    .string().email('Inserire una mail valida')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
    telefono: yup
    .string('Telefono deve essere una stringa')
    .nullable(true)
    .max(15, 'Massimo 15 caratteri'),
    note: yup
    .string('Le note devono essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
    noteModificabili: yup
    .boolean('Tesserato deve essere vero o falso')
    .required('Indicare se tesserato o meno'),
    tesserato: yup
    .boolean('Tesserato deve essere vero o falso')
    .required('Indicare se tesserato o meno'),
});

const validationSchema = yup.object({
    nome: yup
    .string('Nome deve essere una stringa')
    .required('Nome obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    cognome: yup
    .string('Cognome deve essere una stringa')
    .nullable(true)
    .max(50, 'Massimo 50 caratteri'),
    cf: yup
    .string('Codice Fiscale deve essere una stringa')
    .max(50, 'Massimo 50 caratteri'),
    sesso: yup
    .string('Sesso deve essere una stringa')
    .required('Sesso obbligatorio')
    .max(5, 'Scegliere una delle opzioni tra M, F o Altro'),
    indirizzo: yup
    .string('Indirizzo deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
    email: yup
    .string().email('Inserire una mail valida')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
    telefono: yup
    .string('Indirizzo deve essere una stringa')
    .nullable(true)
    .max(15, 'Massimo 15 caratteri'),
    note: yup
    .string('Le note devono essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
});

const stayValidationSchema = yup.object({
    dataIn: yup
    .string('Data In deve essere una stringa')
    .required('Data In obbligatoria'),
    dataOut: yup
    .string('Data Out deve essere una stringa')
    .required('Data Out obbligatoria'),
});

const noteValidationSchema = yup.object({
    note: yup
    .string('Le note devono essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri')
});

export default function NewAdminClient({ pacchetti, stanze, roomTypes, setCurrentOverlayPage, collaborators, SetStatusLed, CreateNewAdminClient, tesseramenti }) {

    let [tesseratiAttivi, setTesseratiAttivi] = useState ([...tesseramenti.filter(tesserato => tesserato.tesseramentoAttivo)]);
    let [shownTesserati, setShownTesserati] = useState ([...tesseramenti.filter(tesserato => tesserato.tesseramentoAttivo)]);

    const [currentPage, setCurrentPage] = useState('newClientData');
    // const [currentPage, setCurrentPage] = useState('selectPath');
    const [newClientData, setNewClientData] = useState({});

    const [clientFormData, setClientFormData] = useState({});

    const [pacchettoScelto, setPacchettoScelto] = useState({});

    const [stanzaScelta, setStanzaScelta] = useState({});
    const [scelteStanza, setScelteStanza] = useState([...stanze]);
    const [roomGroups, setRoomGroups] = useState([]);
    const [roomsGroupingKey, setRoomsGroupingKey] = useState('noGrouping');
    const [roomsOrderingKey, setRoomsOrderingKey] = useState('numero');
    const [roomsSearchResults, setRoomsSearchResults] = useState([...stanze]);

    const [queryingServer, setQueryingServer] = useState(false);

    const handleOrderRoomsByClick = (e) => {
        let sortedRooms = [...scelteStanza];
        let sortedShownRooms = [...roomsSearchResults];
        let orderRoomsBy = e.target.value;

        if (orderRoomsBy === 'numero'){

            setRoomsOrderingKey(orderRoomsBy);
            sortedRooms.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            sortedShownRooms.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderRoomsBy === 'liberi') {

            setRoomsOrderingKey(orderRoomsBy);
            sortedRooms.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            sortedShownRooms.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderRoomsBy === 'capienza') {

            setRoomsOrderingKey(orderRoomsBy);
            sortedRooms.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            sortedShownRooms.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }

        setScelteStanza([...sortedRooms]);
        setRoomsSearchResults([...sortedShownRooms]);

    }

    const handleGroupRoomsByClick = (e) => {
        let newGroups = [];
        let groupRoomsBy = e.target.value;
        
        if (groupRoomsBy === 'tipo') {

            setRoomsGroupingKey(groupRoomsBy);
            scelteStanza.forEach(stanza => {
                if (!(newGroups.includes(stanza.tipo))){
                    newGroups.push(stanza.tipo);
                }
            });
            newGroups.sort((a, b) => {
                let keyA, keyB;
                for (let i = 0; i < roomTypes.length; i++){
                    if (roomTypes[i].tipo == a) {keyA=roomTypes[i].capienza};
                    if (roomTypes[i].tipo == b) {keyB=roomTypes[i].capienza};
                }
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            setRoomGroups([...newGroups]);

        } else if (groupRoomsBy === 'nomeHotel') {

            setRoomsGroupingKey(groupRoomsBy);
            scelteStanza.forEach(stanza => {
                if (!(newGroups.includes(stanza.nomeHotel))){
                    newGroups.push(stanza.nomeHotel);
                }
            });
            newGroups.sort();
            setRoomGroups([...newGroups]);

        } else { //default to 'noGrouping'

            setRoomsGroupingKey('noGrouping');
            setRoomGroups([]);

        }

    }

    const SortShownRooms = (roomsToSort) => {
        
        if (roomsOrderingKey === 'numero'){
            roomsToSort.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (roomsOrderingKey === 'liberi') {
            roomsToSort.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (roomsOrderingKey === 'capienza') {
            roomsToSort.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }

        return roomsToSort;
    }

    const handleChangeClientName = (e, changeHandle) => {

        changeHandle(e);
        let newShownRooms = [];

        if (e.target.value == null || e.target.value == ''){
            newShownRooms = [...stanze];
        } else{

            for (let i = 0; i < stanze.length; i++){

                if (stanze[i].collocati == null) continue;
                if (stanze[i].collocati.length <= 0) continue;

                for (let c = 0; c < stanze[i].collocati.length; c++){
                    let collocatoFullName = stanze[i].collocati[c].cognome == null ? stanze[i].collocati[c].nome : stanze[i].collocati[c].cognome + ' ' + stanze[i].collocati[c].nome;

                    if(collocatoFullName.toLowerCase().includes(e.target.value.toLowerCase())){
                        newShownRooms.push(stanze[i]);
                        break;
                    }

                }

            }

        }

        newShownRooms = SortShownRooms(newShownRooms);
        setRoomsSearchResults([...newShownRooms]);

    }

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);

    useEffect(() => {

        let sortedRooms = [...roomsSearchResults];
        
        sortedRooms.sort((a, b) => {
            let keyA = a.numero, keyB = b.numero;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });

        setRoomsSearchResults([...sortedRooms]);

    }, [])


    const CloseOverlay = () => {
        if (currentPage === 'newClientData') {
            setCurrentOverlayPage('none');
        } else {
            if (window.confirm('Uscendo adesso la creazione del nuovo partecipante sarà interrotta.\nPer creare il partecipante senza aggiungere ulteriori dati cliccare "o Seleziona in un secondo momento".\n\nContinuare?')) {
                setCurrentOverlayPage('none');
            }
        }
    }

    const SubmitClientToCreate = async () => {
        
        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let newClient = newClientData;
        newClient.idEvento = localStorage.getItem('currEventID') == '' ? null: localStorage.getItem('currEventID');

        let newClientID = await CreateClient(newClient);
        
        setQueryingServer(false);
        SetStatusLed(false);


        if (newClientID) {
            newClient.id = newClientID;
            CreateNewAdminClient(newClient);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by CreateClient)
    }


    const AddNewClientData = (data) => {
        let newClient = {
            cfPartecipante: data.cf == '' ? null : data.cf,
            nomePartecipante: data.nome == '' ? null : data.nome,
            cognomePartecipante: data.cognome == '' ? null : data.cognome,
            sessoPartecipante: data.sesso == '' ? null : data.sesso,
            nascitaPartecipante: data.nascita == '' ? null : data.nascita,
            indirizzoPartecipante: data.indirizzo == '' ? null : data.indirizzo,
            mailPartecipante: data.email == '' ? null : data.email,
            telefonoPartecipante: data.telefono == '' ? null : data.telefono,
            notePartecipante: data.note == '' ? null : data.note,
            partecipanteNoteModificabili: data.noteModificabili ?? false,
            partecipanteTesserato: data.tesserato ?? false,
            idCollaboratore: data.idCollaboratore == '' ? null : data.idCollaboratore
        }

        setClientFormData(data);
        setNewClientData(newClient);
        setCurrentPage('selectBundle');
    }

    const AddBundleToNewClient = (pacchetto) => {
        let newClient = newClientData;

        newClient.idPacchetto = pacchetto.id == '' ? null : pacchetto.id;
        newClient.acconto = 0.00;
        newClient.saldo = pacchetto.prezzo === '' ? null : pacchetto.prezzo;
        newClient.totale = pacchetto.prezzo === '' ? null : pacchetto.prezzo;
        newClient.stato = 'Da Pagare';

        setNewClientData(newClient);
        setPacchettoScelto(pacchetto);
        setCurrentPage('selectRoom');

    }

    const addRoomToNewClient = (stanza) => {
        let newClient = newClientData;

        newClient.idStanza = stanza.id == '' ? null : stanza.id;

        setNewClientData(newClient);
        setStanzaScelta(stanza);
        setCurrentPage('defineStay');
    }

    const AddStayToClientAndSubmit = (data) => {
        let newClient = newClientData;

        newClient.dataIn = data.dataIn == '' ? null : data.dataIn;
        newClient.dataOut = data.dataOut == '' ? null : data.dataOut;

        setNewClientData(newClient);
        SubmitClientToCreate();
    }


    const GoBackToNewClientDataPage = () => {
        setNewClientData({});
        setCurrentPage('newClientData');
    }

    const GoBackToSelectBundlePage = () => {
        let newClient = newClientData;

        newClient.idPacchetto = null;
        newClient.acconto = null;
        newClient.saldo = null;
        newClient.totale = null;
        newClient.stato = null;

        setNewClientData(newClient);
        setCurrentPage('selectBundle');
        setPacchettoScelto({});
    }

    const GoBackToSelectRoomPage = () => {
        let newClient = newClientData;

        newClient.idStanza = null;

        setNewClientData(newClient);
        setCurrentPage('selectRoom');
        setStanzaScelta({});

        let sortedRooms = [...stanze];
        
        sortedRooms.sort((a, b) => {
            let keyA = a.numero, keyB = b.numero;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });

        setRoomsSearchResults([...sortedRooms]);

    }

    const GoToSelectTesseratoPage = () => {
        setCurrentPage('selectTesserato');
    }

    const HandleTesseratoSelected = (id) => {
        const tesseratoSelected = tesseratiAttivi.filter(tesseramento => tesseramento.id == id)[0];

        if (tesseratoSelected == null) return;

        setClientFormData({
            nome: tesseratoSelected.nome ?? '',
            cognome: tesseratoSelected.cognome ?? '',
            cf: tesseratoSelected.cf ?? '',
            sesso: tesseratoSelected.sesso ?? '',
            nascita: tesseratoSelected.dataNascita == null ? '' : (tesseratoSelected.dataNascita.split('/').reverse().join('-')),
            indirizzo: tesseratoSelected.indirizzo ?? '',
            email: tesseratoSelected.email ?? '',
            telefono: tesseratoSelected.telefono ?? '',
            tesserato: true,
            idCollaboratore: clientFormData.idCollaboratore ?? ''
        });
        setNewClientData({
            cfPartecipante: tesseratoSelected.cf ?? '',
            nomePartecipante: tesseratoSelected.nome ?? '',
            cognomePartecipante: tesseratoSelected.cognome ?? '',
            sessoPartecipante: tesseratoSelected.sesso ?? '',
            nascitaPartecipante: tesseratoSelected.dataNascita == null ? '' : (tesseratoSelected.dataNascita.split('/').reverse().join('-')),
            indirizzoPartecipante: tesseratoSelected.indirizzo ?? '',
            mailPartecipante: tesseratoSelected.email ?? '',
            telefonoPartecipante: tesseratoSelected.telefono ?? '',
            partecipanteTesserato: true,
            idCollaboratore: clientFormData.idCollaboratore ?? ''
        });

        setCurrentPage('newClientData');
    }

    const handleChangeFind = (e, changeHandle) => {

        changeHandle(e);

        if (e.target.value == null || e.target.value == ''){
            setShownTesserati([...tesseratiAttivi]);
        } else{
            let newShownTesserati = [];

            for (let i = 0; i < tesseratiAttivi.length; i++){
                let fullName = tesseratiAttivi[i].cognome + ' ' + tesseratiAttivi[i].nome;

                if (fullName.toLowerCase().includes(e.target.value.toLowerCase())){
                    newShownTesserati.push(tesseratiAttivi[i]);
                }

            }

            setShownTesserati([...newShownTesserati]);
        }

    }

    return (
        <div id='new-admin-client-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            {/* {currentPage === 'selectTesserato' &&
                <div className='inner-overlay-div'>
                <div className='room-stays-title-div'>
                    <h2> Nuovo Partecipante </h2>
                    {currentPage !== 'overview' &&
                        <a id='back-to-overview' className='clickable' onClick={GoBackToNewClientDataPage}>Indietro</a>
                    }
                </div>
                <div className='overlay-top-divider' />

                <div className='overlay-subtitle'>
                    <h3 className='overlay-subtitle-text'> Aggiungi Tesserato </h3>
                    <div className='overlay-subtitle-divider' />
                </div>

                <div className='overlay-content-div'>
                    
                    <div className='select-client-commands'>
                        <div className='select-client-find'>
                            <h3>Ricerca: </h3>
                            <Formik
                                initialValues = {{find: ''}}
                                onSubmit = { () => {}}
                            >
                                {({ handleChange }) => (
                                    <Form>
                                        <Field id='find-by-name-input' className='text-input' type='text' name='find' onChange={(e) => handleChangeFind(e, handleChange)} />
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        --- NON UTILE, TANTO DOVREMMO AVERE UNA PERSONA IN PARTICOLARE CHE VOGLIAMO AGGIUNGERE
                        <div className='select-client-order'>
                            <h3>Ordina per: </h3>
                            <Formik
                                initialValues={{
                                    orderClientsBy: clientiOrder,
                                }}
                            >
                                {() => (
                                    <Form>
                                        <label> <Field type='radio' name='orderClientsBy' value='cognome' onClick={e => setClientiOrder(e.target.value)} /> <a>{'Cognome'}</a> </label>
                                        <label> <Field type='radio' name='orderClientsBy' value='nome' onClick={e => setClientiOrder(e.target.value)} /> <a>{'Nome'}</a> </label>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        ---
                    </div>

                    <div className='overlay-choice-list-top-fader' />

                    <div className='overlay-choice-list'>

                            <div className='grid-content-div no-padding'>
                                {
                                shownTesserati.map(client =>
                                    <ChoiceTesserato key={client.id} {...client} HandleTesseratoSelected={HandleTesseratoSelected} />
                                )
                                }
                            </div>

                    </div>

                </div>
                
                </div>
            } */}

            {currentPage === 'newClientData' &&
                <div className='inner-overlay-div'>
                    <h2> Nuovo Partecipante </h2>
                    <div className='overlay-top-divider' />
                    
                    <div className='overlay-content-div'>
                        <Formik
                            initialValues={{
                                nome: clientFormData.nome == null ? '' : clientFormData.nome,
                                cognome: clientFormData.cognome == null ? '' : clientFormData.cognome,
                                cf: clientFormData.cf == null ? '' : clientFormData.cf,
                                sesso: clientFormData.sesso == null ? '' : clientFormData.sesso,
                                nascita: clientFormData.nascita == null ? '' : clientFormData.nascita,
                                indirizzo: clientFormData.indirizzo == null ? '' : clientFormData.indirizzo,
                                email: clientFormData.email == null ? '' : clientFormData.email,
                                telefono: clientFormData.telefono == null ? '' : clientFormData.telefono,
                                note: clientFormData.note == null ? '' : clientFormData.note,
                                noteModificabili: clientFormData.noteModificabili ?? true,
                                tesserato: clientFormData.tesserato ?? false,
                                idCollaboratore: clientFormData.idCollaboratore == null ? '' : clientFormData.idCollaboratore
                            }}
                            validationSchema={fullValidationSchema}
                            onSubmit={(data) => AddNewClientData(data)}
                        >

                        {({ errors, touched }) => (
                            <Form autoComplete='off'>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Cognome: </h4><Field className='overlay-text-input' type='text' name='cognome' as={TextField} /> {touched.cognome && errors.cognome ? <a className='validation'>{errors.cognome}</a> : ''} </div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Codice Fiscale: </h4><Field className='overlay-text-input' type='text' name='cf' as={TextField} /> {touched.cf && errors.cf ? <a className='validation'>{errors.cf}</a> : ''} </div>
                                
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Sesso: </h4>
                                <Field name='sesso' as={Select} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                                    <MenuItem value='M'>M</MenuItem>
                                    <MenuItem value='F'>F</MenuItem>
                                    <MenuItem value='Altro'>Altro</MenuItem>
                                </Field>
                                {touched.sesso && errors.sesso ? <a className='validation'>{errors.sesso}</a> : ''} 
                                </div>
                                
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data di nascita: </h4><Field className='overlay-date-input' type='date' name='nascita' as={isMobile ? '' : TextField} /></div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Indirizzo: </h4><Field className='overlay-text-input' type='text' name='indirizzo' as={TextField} /> {touched.indirizzo && errors.indirizzo ? <a className='validation'>{errors.indirizzo}</a> : ''} </div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Mail: </h4><Field className='overlay-text-input' type='text' name='email' as={TextField} /> {touched.email && errors.email ? <a className='validation'>{errors.email}</a> : ''} </div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Telefono: </h4><Field className='overlay-text-input' type='text' name='telefono' as={TextField} /> {touched.telefono && errors.telefono ? <a className='validation'>{errors.telefono}</a> : ''} </div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} />{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>

                                <div className='overlay-input-field'> <h4 className='overlay-input-key'> Note Modificabili: </h4><Field className='overlay-text-input' type='checkbox' name='noteModificabili' /> {touched.noteModificabili && errors.noteModificabili ? <a className='validation'>{errors.noteModificabili}</a> : ''} </div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'> Tesserato: </h4><Field className='overlay-text-input' type='checkbox' name='tesserato' /> {touched.tesserato && errors.tesserato ? <a className='validation'>{errors.tesserato}</a> : ''} </div>
                                
                                <div className='overlay-input-field'><h4 className='overlay-input-key'>Collaboratore: </h4>
                                <Field name='idCollaboratore' as={Select} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                                    <MenuItem value=''>Nessuno</MenuItem>
                                    {collaborators.filter(collaborator => !collaborator.isAdmin).map( collaborator =>{
                                        return(
                                            <MenuItem value={collaborator.id}>{collaborator.cognome == null ? collaborator.nome : (collaborator.cognome + ' ' + collaborator.nome)}</MenuItem>
                                        )
                                    })
                                    }
                                </Field>
                                {touched.idCollaboratore && errors.idCollaboratore ? <a className='validation'>{errors.idCollaboratore}</a> : ''}
                                </div>
                                
                                <button type='submit'> Continua </button>
                                {/* <a className='button-alternative-link' onClick={GoToSelectTesseratoPage}>o Seleziona tra i tesserati</a> */}
                            </Form>
                        )}

                        </Formik>
                    </div>
                </div>
            }

            {currentPage === 'selectBundle' &&
                <div className='inner-overlay-div'>
                    <div className='room-stays-title-div'>
                        <h2> {newClientData.cognomePartecipante} {newClientData.nomePartecipante} </h2>
                        {currentPage !== 'overview' &&
                            <a id='back-to-overview' className='clickable' onClick={GoBackToNewClientDataPage}>Indietro</a>
                        }
                    </div>
                    <div className='overlay-top-divider' />

                    <div className='overlay-subtitle'>
                        <h3 className='overlay-subtitle-text'> Seleziona Pacchetto </h3>
                        <div className='overlay-subtitle-divider' />
                        <h5 className='clickable' onClick={SubmitClientToCreate}> o Seleziona in un secondo momento </h5>
                    </div>

                    <div className='overlay-choice-list-top-fader' />
                    <div className='overlay-choice-list'>

                        <div className='grid-content-div no-padding'>
                            {
                            pacchetti.map(pacchetto =>
                                <div key={pacchetto.id} className='bundle-choice'>
                                    <Bundle {...pacchetto} />
                                    <button onClick={() => AddBundleToNewClient(pacchetto)}> Seleziona Pacchetto </button>
                                </div>
                            )
                            }
                        </div>

                        {/* <Masonry
                            items={
                                pacchetti.map(pacchetto => {
                                    return(
                                        {key: pacchetto.id, node:
                                            <div className='bundle-choice'>
                                                <Bundle {...pacchetto} />
                                                <button onClick={() => AddBundleToNewClient(pacchetto)}> Seleziona Pacchetto </button>
                                            </div>
                                        }
                                        
                                    )
                                })
                            }
                            minColumnWidth={240}
                            gap={16}
                        /> */}
                    </div>
                </div>
            }

            {currentPage === 'selectRoom' &&
                <div className='inner-overlay-div'>
                    <div className='room-stays-title-div'>
                        <h2> {newClientData.cognomePartecipante} {newClientData.nomePartecipante} </h2>
                        {currentPage !== 'overview' &&
                            <a id='back-to-overview' className='clickable' onClick={GoBackToSelectBundlePage}>Indietro</a>
                        }
                    </div>
                    <div className='overlay-top-divider' />

                    <div className='overlay-subtitle'>
                        <h3 className='overlay-subtitle-text'> Seleziona Camera </h3>
                        <div className='overlay-subtitle-divider' />
                        <h5 className='clickable' onClick={SubmitClientToCreate}> o Seleziona in un secondo momento </h5>
                    </div>

                    <div className='select-room-commands'>

                        <div className='select-room-find-by-client'>
                            <h3>Ricerca per partecipante: </h3>
                            <Formik
                                initialValues = {{clientName: ''}}
                                onSubmit = { () => {}}
                            >
                                {({ handleChange }) => (
                                    <Form>
                                        <Field id='find-by-name-input' className='text-input' type='text' name='clientName' onChange={(e) => handleChangeClientName(e, handleChange)} />
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='select-room-radio-commands'>
                            <div className='select-room-order-rooms'>
                                <h3>Ordina per: </h3>
                                <Formik
                                    initialValues={{
                                        orderRoomsBy: roomsOrderingKey,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderRoomsBy' value='numero' onClick={handleOrderRoomsByClick} /> <a>{'Numero di camera'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='liberi' onClick={handleOrderRoomsByClick} /> <a>{'Posti liberi'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='capienza' onClick={handleOrderRoomsByClick} /> <a>{'Capienza massima'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='select-room-group-rooms'>
                                <h3>Raggruppa per: </h3>
                                <Formik
                                    initialValues={{
                                        groupRoomsBy: roomsGroupingKey,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='groupRoomsBy' value='nomeHotel' onClick={handleGroupRoomsByClick} /> <a>{'Hotel'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='tipo' onClick={handleGroupRoomsByClick} /> <a>{'Tipo di camera'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='noGrouping' onClick={handleGroupRoomsByClick} /> <a>{'Non raggruppare'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                        </div>
                    </div>
                        
                    <div className='overlay-choice-list-top-fader' />
                    
                    <div className='overlay-choice-list'>

                        {roomGroups.length <= 0 && roomsSearchResults.filter(stanza => !stanza.piena).length > 0 && //No grouping
                            <div className='grid-content-div no-padding'>
                                {
                                roomsSearchResults.filter(stanza => !stanza.piena).map(stanza =>
                                    <div key={stanza.id} className='room-choice'>
                                        <ChoiceRoom {...stanza} />
                                        <button onClick={() => addRoomToNewClient(stanza)} className='select-room-button' > Seleziona Camera </button>
                                    </div>
                                )
                                }
                            </div>

                            // <Masonry
                            //     items={
                            //         roomsSearchResults.filter(stanza => !stanza.piena).map(stanza => {
                            //             return(
                            //                 {key: stanza.id, node:
                            //                     <div className='room-choice'>
                            //                         <ChoiceRoom {...stanza} />
                            //                         <button onClick={() => addRoomToNewClient(stanza)} className='select-room-button' > Seleziona Camera </button>
                            //                     </div>
                            //                 }
                                            
                            //             )
                            //         })
                            //     }
                            //     minColumnWidth={272}
                            //     gap={16}
                            // />
                        }

                        {roomGroups.length <= 0 && roomsSearchResults.filter(stanza => !stanza.piena).length <= 0 && //No grouping
                            <h4 className='no-room-found'>Nessuna stanza libera trovata!</h4>
                        }


                        {roomGroups.length > 0 && //Standard key-value grouping
                            roomGroups.filter(groupName => scelteStanza.filter(stanza => (!stanza.piena) && stanza[roomsGroupingKey] == groupName).length > 0).map( groupName => {
                                return(
                                    <div className='groups-div'>
                                        <div className='group-div'>
                                            <h3> {groupName} </h3>
                                            <div className='group-divider' />

                                            <div className='grid-content-div no-padding'>
                                                {
                                                scelteStanza.filter(stanza => (!stanza.piena) && stanza[roomsGroupingKey] == groupName).map(stanza =>
                                                    <div key={stanza.id} className='room-choice'>
                                                        <ChoiceRoom {...stanza} />
                                                        <button onClick={() => addRoomToNewClient(stanza)} className='select-room-button' > Seleziona Camera </button>
                                                    </div>
                                                )
                                                }
                                            </div>

                                            {/* <Masonry
                                                items={
                                                    scelteStanza.filter(stanza => (!stanza.piena) && stanza[roomsGroupingKey] == groupName).map(stanza => {
                                                        return(
                                                            {key: stanza.id, node:
                                                                <div className='room-choice'>
                                                                    <ChoiceRoom {...stanza} />
                                                                    <button onClick={() => addRoomToNewClient(stanza)} className='select-room-button' > Seleziona Camera </button>
                                                                </div>
                                                            }
                                                            
                                                        )
                                                    })
                                                }
                                                minColumnWidth={272}
                                                gap={16}
                                            /> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }

            {currentPage === 'defineStay' &&
                <div className='inner-overlay-div'>
                    <div className='room-stays-title-div'>
                        <h2> {newClientData.cognomePartecipante} {newClientData.nomePartecipante} </h2>
                        {currentPage !== 'overview' &&
                            <a id='back-to-overview' className='clickable' onClick={GoBackToSelectRoomPage}>Indietro</a>
                        }
                    </div>
                    <div className='overlay-top-divider' />

                    <div className='overlay-subtitle'>
                        <h3 className='overlay-subtitle-text'> Pernottamento </h3>
                        <div className='overlay-subtitle-divider' />
                    </div>
                    
                    <div className='overlay-stay-content-div'>
                        <div className='stay-content-chosen-room'>
                            <h4> Camera: {stanzaScelta.codice} - {stanzaScelta.nomeHotel}  </h4><a>-</a><a className='clickable' onClick={GoBackToSelectRoomPage}>Modifica</a>
                        </div>

                        <Formik
                            initialValues={{
                                dataIn: pacchettoScelto.dataInizio.split('/').reverse().join('-'),
                                dataOut: pacchettoScelto.dataFine.split('/').reverse().join('-')
                            }}
                            validationSchema={stayValidationSchema}
                            onSubmit={(data) => AddStayToClientAndSubmit(data)}
                        >

                        {({ errors, touched }) => (
                            <Form autoComplete='off'>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data In: </h4><Field className='overlay-date-input' type='date' name='dataIn' as={isMobile ? '' : TextField} autoFocus/> {touched.dataIn && errors.dataIn ? <a className='validation'>{errors.dataIn}</a> : ''} </div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Out: </h4><Field className='overlay-date-input' type='date' name='dataOut' as={isMobile ? '' : TextField} /> {touched.dataOut && errors.dataOut ? <a className='validation'>{errors.dataOut}</a> : ''} </div>
                                <button type='submit'> Conferma </button>
                            </Form>
                        )}

                        </Formik>

                    </div>
                    

                </div>
            }

        </div>
    )
}

export function SelectRoomForClient({ setCurrentOverlayPage, currentlyEditedClient, stanze, SetClientRoom, roomTypes, SetStatusLed }) {

    const [isSubmitting, setSubmitting] = useState(false);
    const [scelteStanza, setScelteStanza] = useState([...stanze]);
    const [roomGroups, setRoomGroups] = useState([]);
    const [roomsGroupingKey, setRoomsGroupingKey] = useState('noGrouping');
    const [roomsOrderingKey, setRoomsOrderingKey] = useState('numero');
    const [roomsSearchResults, setRoomsSearchResults] = useState([...stanze]);

    let [stanzaScelta, setStanzaScelta] = useState();

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const GoBackToRoomChoice = () => {
        let sortedRooms = [...stanze];
        
        sortedRooms.sort((a, b) => {
            let keyA = a.numero, keyB = b.numero;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });

        setRoomsSearchResults([...sortedRooms]);
        setStanzaScelta(null);
    }

    const HandleSelectRoom = async (data, {setSubmitting}) => {

        let stanza = stanzaScelta;
        stanza.dataIn = data.dataIn.split('-').reverse().join('/');
        stanza.dataOut = data.dataOut.split('-').reverse().join('/');

        setSubmitting(true);
        SetStatusLed(true);

        let pernottamentoData = {
            idPartecipante: currentlyEditedClient.id,
            idStanza: stanza.id,
            dataIn: data.dataIn,
            dataOut: data.dataOut
        }
        
        let res = false;

        if (currentlyEditedClient.idStanza == null || currentlyEditedClient.idStanza == ''){ //Client doesn't have a room yet
            res = await AddClientWithBundleToRoom(pernottamentoData);
        } else{ //Client is already in a room, we are modifying the pernottamento
            res = await UpdatePernottamentoData(pernottamentoData);
        }
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            SetClientRoom(stanza);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by CreateClient)
        

    }

    const handleOrderRoomsByClick = (e) => {
        let sortedRooms = [...scelteStanza];
        let sortedShownRooms = [...roomsSearchResults];
        let orderRoomsBy = e.target.value;

        if (orderRoomsBy === 'numero'){

            setRoomsOrderingKey(orderRoomsBy);
            sortedRooms.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            sortedShownRooms.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderRoomsBy === 'liberi') {

            setRoomsOrderingKey(orderRoomsBy);
            sortedRooms.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            sortedShownRooms.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderRoomsBy === 'capienza') {

            setRoomsOrderingKey(orderRoomsBy);
            sortedRooms.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            sortedShownRooms.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }

        setScelteStanza([...sortedRooms]);
        setRoomsSearchResults([...sortedShownRooms]);

    }

    const handleGroupRoomsByClick = (e) => {
        let newGroups = [];
        let groupRoomsBy = e.target.value;
        
        if (groupRoomsBy === 'tipo') {

            setRoomsGroupingKey(groupRoomsBy);
            scelteStanza.forEach(stanza => {
                if (!(newGroups.includes(stanza.tipo))){
                    newGroups.push(stanza.tipo);
                }
            });
            newGroups.sort((a, b) => {
                let keyA, keyB;
                for (let i = 0; i < roomTypes.length; i++){
                    if (roomTypes[i].tipo == a) {keyA=roomTypes[i].capienza};
                    if (roomTypes[i].tipo == b) {keyB=roomTypes[i].capienza};
                }
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            setRoomGroups([...newGroups]);

        } else if (groupRoomsBy === 'nomeHotel') {

            setRoomsGroupingKey(groupRoomsBy);
            scelteStanza.forEach(stanza => {
                if (!(newGroups.includes(stanza.nomeHotel))){
                    newGroups.push(stanza.nomeHotel);
                }
            });
            newGroups.sort();
            setRoomGroups([...newGroups]);

        } else { //default to 'noGrouping'

            setRoomsGroupingKey('noGrouping');
            setRoomGroups([]);

        }

    }

    const SortShownRooms = (roomsToSort) => {
        
        if (roomsOrderingKey === 'numero'){
            roomsToSort.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (roomsOrderingKey === 'liberi') {
            roomsToSort.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (roomsOrderingKey === 'capienza') {
            roomsToSort.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }

        return roomsToSort;
    }

    const handleChangeClientName = (e, changeHandle) => {

        changeHandle(e);
        let newShownRooms = [];

        if (e.target.value == null || e.target.value == ''){
            newShownRooms = [...stanze];
        } else{

            for (let i = 0; i < stanze.length; i++){

                if (stanze[i].collocati == null) continue;
                if (stanze[i].collocati.length <= 0) continue;

                for (let c = 0; c < stanze[i].collocati.length; c++){
                    let collocatoFullName = stanze[i].collocati[c].cognome == null ? stanze[i].collocati[c].nome : stanze[i].collocati[c].cognome + ' ' + stanze[i].collocati[c].nome;

                    if(collocatoFullName.toLowerCase().includes(e.target.value.toLowerCase())){
                        newShownRooms.push(stanze[i]);
                        break;
                    }

                }

            }

        }

        newShownRooms = SortShownRooms(newShownRooms);
        setRoomsSearchResults([...newShownRooms]);

    }
    
    useEffect(() => {
        let sortedRooms = [...roomsSearchResults];
        
        sortedRooms.sort((a, b) => {
            let keyA = a.numero, keyB = b.numero;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });

        setRoomsSearchResults([...sortedRooms]);

    }, [])

    return (
        <div id='select-room-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> {currentlyEditedClient.cognome} {currentlyEditedClient.nome} {currentlyEditedClient.tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h2>
                <div className='overlay-top-divider' />


                {stanzaScelta == null &&
                    <div className='overlay-subtitle'>
                        <h3 className='overlay-subtitle-text'> Seleziona Camera </h3>
                        <div className='overlay-subtitle-divider' />
                        <h5 className='clickable' onClick={CloseOverlay}> o Seleziona in un secondo momento </h5>
                    </div>
                }

                {stanzaScelta == null &&
                    <div className='select-room-commands'>

                        <div className='select-room-find-by-client'>
                            <h3>Ricerca per partecipante: </h3>
                            <Formik
                                initialValues = {{clientName: ''}}
                                onSubmit = { () => {}}
                            >
                                {({ handleChange }) => (
                                    <Form>
                                        <Field id='find-by-name-input' className='text-input' type='text' name='clientName' onChange={(e) => handleChangeClientName(e, handleChange)} />
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='select-room-radio-commands'>
                            <div className='select-room-order-rooms'>
                                <h3>Ordina per: </h3>
                                <Formik
                                    initialValues={{
                                        orderRoomsBy: roomsOrderingKey,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderRoomsBy' value='numero' onClick={handleOrderRoomsByClick} /> <a>{'Numero di camera'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='liberi' onClick={handleOrderRoomsByClick} /> <a>{'Posti liberi'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='capienza' onClick={handleOrderRoomsByClick} /> <a>{'Capienza massima'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='select-room-group-rooms'>
                                <h3>Raggruppa per: </h3>
                                <Formik
                                    initialValues={{
                                        groupRoomsBy: roomsGroupingKey,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='groupRoomsBy' value='nomeHotel' onClick={handleGroupRoomsByClick} /> <a>{'Hotel'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='tipo' onClick={handleGroupRoomsByClick} /> <a>{'Tipo di camera'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='noGrouping' onClick={handleGroupRoomsByClick} /> <a>{'Non raggruppare'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                }
                    
                {stanzaScelta == null && <div className='overlay-choice-list-top-fader'></div> }
                {stanzaScelta == null &&
                <div className='overlay-choice-list'>

                    {roomGroups.length <= 0 && roomsSearchResults.filter(stanza => !stanza.piena || currentlyEditedClient.idStanza == stanza.id).length > 0 && //No grouping
                        <div className='grid-content-div no-padding'>
                            {
                            roomsSearchResults.filter(stanza => !stanza.piena || currentlyEditedClient.idStanza == stanza.id).map(stanza =>
                                <div key={stanza.id} className='room-choice'>
                                    <ChoiceRoom {...stanza} />
                                    {(currentlyEditedClient.idStanza == null || currentlyEditedClient.idStanza != stanza.id) &&
                                        <button onClick={() => setStanzaScelta(stanza)} disabled={isSubmitting} className='select-room-button' > Seleziona Camera </button>
                                    }
                                    {currentlyEditedClient.idStanza == stanza.id &&
                                        <div className='already-selected-room clickable' onClick={() => setStanzaScelta(stanza)} ><h4> Mantieni camera </h4></div>
                                    }
                                </div>
                            )
                            }
                        </div>

                        // <Masonry
                        //     items={
                        //         roomsSearchResults.filter(stanza => !stanza.piena || currentlyEditedClient.idStanza == stanza.id).map(stanza => {
                        //             return(
                        //                 {key: stanza.id, node:
                        //                     <div className='room-choice'>
                        //                         <ChoiceRoom {...stanza} />
                        //                         {(currentlyEditedClient.idStanza == null || currentlyEditedClient.idStanza != stanza.id) &&
                        //                             <button onClick={() => setStanzaScelta(stanza)} disabled={isSubmitting} className='select-room-button' > Seleziona Camera </button>
                        //                         }
                        //                         {currentlyEditedClient.idStanza == stanza.id &&
                        //                             <div className='already-selected-room clickable' onClick={() => setStanzaScelta(stanza)} ><h4> Mantieni camera </h4></div>
                        //                         }
                        //                     </div>
                        //                 }
                                        
                        //             )
                        //         })
                        //     }
                        //     minColumnWidth={272}
                        //     gap={16}
                        // />
                    }

                    {roomGroups.length <= 0 && roomsSearchResults.filter(stanza => !stanza.piena || currentlyEditedClient.idStanza == stanza.id).length <= 0 && //No grouping
                        <h4 className='no-room-found'>Nessuna stanza libera trovata!</h4>
                    }


                    {roomGroups.length > 0 && //Standard key-value grouping
                        roomGroups.filter(groupName => scelteStanza.filter(stanza => (!stanza.piena || currentlyEditedClient.idStanza == stanza.id) && stanza[roomsGroupingKey] == groupName).length > 0).map( groupName => {
                            return(
                                <div className='groups-div'>
                                    <div className='group-div'>
                                        <h3> {groupName} </h3>
                                        <div className='group-divider' />

                                        <div className='grid-content-div no-padding'>
                                            {
                                            scelteStanza.filter(stanza => (!stanza.piena || currentlyEditedClient.idStanza == stanza.id) && stanza[roomsGroupingKey] == groupName).map(stanza =>
                                                <div key={stanza.id} className='room-choice'>
                                                    <ChoiceRoom {...stanza} />
                                                    {(currentlyEditedClient.idStanza == null || currentlyEditedClient.idStanza != stanza.id) &&
                                                        <button onClick={() => setStanzaScelta(stanza)} disabled={isSubmitting} className='select-room-button' > Seleziona Camera </button>
                                                    }
                                                    {currentlyEditedClient.idStanza == stanza.id &&
                                                        <div className='already-selected-room clickable' onClick={() => setStanzaScelta(stanza)} ><h4> Mantieni camera </h4></div>
                                                    }
                                                </div>
                                            )
                                            }
                                        </div>

                                        {/* <Masonry
                                            items={
                                                scelteStanza.filter(stanza => (!stanza.piena || currentlyEditedClient.idStanza == stanza.id) && stanza[roomsGroupingKey] == groupName).map(stanza => {
                                                    return(
                                                        {key: stanza.id, node:
                                                            <div className='room-choice'>
                                                                <ChoiceRoom {...stanza} />
                                                                {(currentlyEditedClient.idStanza == null || currentlyEditedClient.idStanza != stanza.id) &&
                                                                    <button onClick={() => setStanzaScelta(stanza)} disabled={isSubmitting} className='select-room-button' > Seleziona Camera </button>
                                                                }
                                                                {currentlyEditedClient.idStanza == stanza.id &&
                                                                    <div className='already-selected-room clickable' onClick={() => setStanzaScelta(stanza)} ><h4> Mantieni camera </h4></div>
                                                                }
                                                            </div>
                                                        }
                                                        
                                                    )
                                                })
                                            }
                                            minColumnWidth={272}
                                            gap={16}
                                        /> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                    

                </div>
                } 

                {stanzaScelta != null &&
                    <div className='overlay-subtitle'>
                        <h3 className='overlay-subtitle-text'> Pernottamento </h3>
                        <div className='overlay-subtitle-divider' />
                    </div>
                }

                {stanzaScelta != null &&
                    <div className='overlay-stay-content-div'>
                        <div className='stay-content-chosen-room'>
                            <h4> Camera: {stanzaScelta.codice} - {stanzaScelta.nomeHotel}  </h4><a>-</a><a className='clickable' onClick={GoBackToRoomChoice}>Modifica</a>
                        </div>

                        <Formik
                            initialValues={{
                                dataIn: currentlyEditedClient.dataInizioPacchetto == null ? null : currentlyEditedClient.dataInizioPacchetto.split('/').reverse().join('-'),
                                dataOut: currentlyEditedClient.dataFinePacchetto == null ? null : currentlyEditedClient.dataFinePacchetto.split('/').reverse().join('-')
                            }}
                            validationSchema={stayValidationSchema}
                            onSubmit={(data, {setSubmitting}) => HandleSelectRoom(data, {setSubmitting})}
                        >

                        {({ errors, touched, isSubmitting }) => (
                            <Form autoComplete='off'>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data In: </h4><Field className='overlay-date-input' type='date' name='dataIn' as={TextField} autoFocus/> {touched.dataIn && errors.dataIn ? <a className='validation'>{errors.dataIn}</a> : ''} </div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Out: </h4><Field className='overlay-date-input' type='date' name='dataOut' as={TextField} /> {touched.dataOut && errors.dataOut ? <a className='validation'>{errors.dataOut}</a> : ''} </div>
                                <button disabled={isSubmitting} type='submit'> Conferma </button>
                            </Form>
                        )}

                        </Formik>

                    </div>
                }

            </div>
            
        </div>
    )

}

export function ModifyAdminClientData({ setCurrentOverlayPage, currentlyEditedClient, ModifyAdminClient, collaborators, SetStatusLed }){

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let updatedClientData = {
            id: currentlyEditedClient.id,
            cf: data.cf == '' ? null : data.cf,
            nome: data.nome == '' ? null : data.nome,
            cognome: data.cognome == '' ? null : data.cognome,
            sesso: data.sesso == '' ? null : data.sesso,
            nascita: data.nascita == '' ? null : data.nascita,
            indirizzo: data.indirizzo == '' ? null : data.indirizzo,
            email: data.email == '' ? null : data.email,
            telefono: data.telefono == '' ? null : data.telefono,
            note: data.note == '' ? null : data.note,
            idCollaboratore: data.idCollaboratore == '' ? null : data.idCollaboratore
        }
        
        let res = await UpdateDBClientData(updatedClientData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            let updatedClient = currentlyEditedClient;
            
            updatedClient.cf = data.cf;
            updatedClient.nome = data.nome;
            updatedClient.cognome = data.cognome;
            updatedClient.sesso = data.sesso;
            updatedClient.nascita = data.nascita == null ? null : data.nascita.split('-').reverse().join('/');
            updatedClient.indirizzo = data.indirizzo;
            updatedClient.email = data.email;
            updatedClient.telefono = data.telefono;
            updatedClient.note = data.note;
            updatedClient.idCollaboratore = data.idCollaboratore;

            ModifyAdminClient(updatedClient);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBClientData)

    }

    return (
        <div id='modify-admin-client-data-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Modifica Partecipante </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            nome: currentlyEditedClient.nome,
                            cognome: currentlyEditedClient.cognome == null ? '' : currentlyEditedClient.cognome,
                            cf: currentlyEditedClient.cf == null ? '' : currentlyEditedClient.cf,
                            sesso: currentlyEditedClient.sesso,
                            nascita: currentlyEditedClient.nascita == null ? '' : currentlyEditedClient.nascita.split('/').reverse().join('-'),
                            indirizzo: currentlyEditedClient.indirizzo == null ? '' : currentlyEditedClient.indirizzo,
                            email: currentlyEditedClient.email == null ? '' : currentlyEditedClient.email,
                            telefono: currentlyEditedClient.telefono == null ? '' : currentlyEditedClient.telefono,
                            note: currentlyEditedClient.note == null ? '' : currentlyEditedClient.note,
                            idCollaboratore: currentlyEditedClient.idCollaboratore == null ? '' : currentlyEditedClient.idCollaboratore
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Cognome: </h4><Field className='overlay-text-input' type='text' name='cognome' as={TextField} /> {touched.cognome && errors.cognome ? <a className='validation'>{errors.cognome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Codice Fiscale: </h4><Field className='overlay-text-input' type='text' name='cf' as={TextField} /> {touched.cf && errors.cf ? <a className='validation'>{errors.cf}</a> : ''} </div>
                            
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Sesso: </h4>
                            <Field name='sesso' as={Select} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                                <MenuItem value='M'>M</MenuItem>
                                <MenuItem value='F'>F</MenuItem>
                                <MenuItem value='Altro'>Altro</MenuItem>
                            </Field>
                            {touched.sesso && errors.sesso ? <a className='validation'>{errors.sesso}</a> : ''} 
                            </div>
                            
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data di nascita: </h4><Field className='overlay-date-input' type='date' name='nascita' as={isMobile ? '' : TextField} /></div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Indirizzo: </h4><Field className='overlay-text-input' type='text' name='indirizzo' as={TextField} /> {touched.indirizzo && errors.indirizzo ? <a className='validation'>{errors.indirizzo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Mail: </h4><Field className='overlay-text-input' type='text' name='email' as={TextField} /> {touched.email && errors.email ? <a className='validation'>{errors.email}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Telefono: </h4><Field className='overlay-text-input' type='text' name='telefono' as={TextField} /> {touched.telefono && errors.telefono ? <a className='validation'>{errors.telefono}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} />{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                            
                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Collaboratore: </h4>
                            <Field name='idCollaboratore' as={Select} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                                <MenuItem value=''>Nessuno</MenuItem>
                                {collaborators.filter(collaborator => !collaborator.isAdmin).map( collaborator =>{
                                    return(
                                        <MenuItem value={collaborator.id}>{collaborator.cognome == null ? collaborator.nome : (collaborator.cognome + ' ' + collaborator.nome)}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.idCollaboratore && errors.idCollaboratore ? <a className='validation'>{errors.idCollaboratore}</a> : ''}
                            </div>
                            
                            <button disabled={isSubmitting} type='submit'> Conferma </button>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}

export function ChangeAdminClientNotes({ setCurrentOverlayPage, currentlyEditedClient, SetStatusLed, ModifyAdminClient }){

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);
        
        let res = await UpdateClienteNote({
            idPartecipante: currentlyEditedClient.id,
            note: data.note == '' ? null : data.note,
        });
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            let updatedClient = currentlyEditedClient;

            updatedClient.note = data.note;

            ModifyAdminClient(updatedClient);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBClientData)

    }

    return (
        <div id='modify-client-data-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> {currentlyEditedClient.cognome} {currentlyEditedClient.nome} </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            note: currentlyEditedClient.note == null ? '' : currentlyEditedClient.note,
                        }}
                        validationSchema={noteValidationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} autoFocus/>{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                            <button disabled={isSubmitting} type='submit'> Continua </button>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}